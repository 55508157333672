<template>
    <v-app app :class="loading == false ? 'primary' : ''">
        <loading v-if="loading"></loading>
        <div  v-if="!loading">
            <v-row justify="center" align="center" height="400px" style="margin-top: 120px;">
                <v-col>
                    <v-card class="mx-auto" outlined width="700"  style="border-radius: 40px; border: solid 3px #CFD8DC">
                        <v-container>
                            <div class="text-center">
                                <v-avatar size="125" style="border-radius: 30px; margin-top: -70px;border: solid 3px #CFD8DC" v-if="item.imageUrl">
                                    <v-img :src="$url+'/'+item.imageUrl" />
                                </v-avatar>
                                <v-avatar size="125" style="border-radius: 30px" v-else>
                                    <img contain src="@/assets/images/photo.svg">
                                </v-avatar>
                                <h1 class="mt-6 primary--text">
                                    {{ item.fullName }}
                                </h1>
                                <h2>
                                    {{ item.description }}
                                </h2>
                            </div>
                            <v-divider class="my-4"/>
                            <div v-html="item.content"></div>

                            <!-- <v-expansion-panels outlined flat style="border: solid 1px #E0E0E0; border-radius: 40px" v-if="item.doctorArticles.length > 0">
                                <v-expansion-panel style="border: solid 1px #E0E0E0; border-radius: 40px">
                                    <v-expansion-panel-header>
                                        <h3>
                                            مقالات الطبيب
                                        </h3>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                    <v-divider/>
                                            <v-alert  v-for="(a, i) in item.doctorArticles" :key="i" color="primary" text >
                                                <v-row class="ma-2">
                                                    <h3>
                                                        {{ a.title }}
                                                    </h3>
                                                    <v-spacer/>
                                                    <v-btn outlined rounded color="indigo" :href="$url+'/'+a.fileUrl">
                                                       عرض المقالة 
                                                    </v-btn>
                                                </v-row>
                                            </v-alert>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels> -->
                            <v-alert  v-for="(a, i) in item.doctorArticles" :key="i" color="primary" text >
                                <v-row class="ma-2">
                                    <h3>
                                        {{ a.title }}
                                    </h3>
                                    <v-spacer/>
                                    <v-btn outlined rounded color="indigo" :href="$url+'/'+a.fileUrl">
                                       عرض المقالة 
                                    </v-btn>
                                </v-row>
                            </v-alert>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<script>
export default {

    data() {
        return {
            searchName: '',
            item: [],
            loading: false
        }
    },

    created() {
        this.getItems()
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Doctor/${this.$route.params.id}`)
                this.item = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
